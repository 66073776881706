/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    pre: "pre",
    code: "code",
    strong: "strong",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "webpack",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#webpack",
    "aria-label": "webpack permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Webpack"), "\n", React.createElement(_components.p, null, "SVGR provides an official ", React.createElement(_components.a, {
    href: "https://webpack.js.org/"
  }, "webpack.js"), " loader to import SVG as React components."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.h2, {
    id: "install",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#install",
    "aria-label": "install permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Install"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "npm install --save-dev @svgr/webpack\n# or use yarn\nyarn add --dev @svgr/webpack\n")), "\n", React.createElement(_components.h2, {
    id: "usage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#usage",
    "aria-label": "usage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Usage"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "webpack.config.js")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "module.exports = {\n  module: {\n    rules: [\n      {\n        test: /\\.svg$/i,\n        issuer: /\\.[jt]sx?$/,\n        use: ['@svgr/webpack'],\n      },\n    ],\n  },\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Your code")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import Star from './star.svg'\n\nconst Example = () => (\n  <div>\n    <Star />\n  </div>\n)\n")), "\n", React.createElement(_components.h2, {
    id: "options",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#options",
    "aria-label": "options permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Options"), "\n", React.createElement(_components.p, null, "SVGR let you specify options in a runtime config file like ", React.createElement(_components.code, null, "svgr.config.js"), " or directly in the loader:"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "webpack.config.js")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "module.exports = {\n  module: {\n    rules: [\n      {\n        test: /\\.svg$/i,\n        issuer: /\\.[jt]sx?$/,\n        use: [{ loader: '@svgr/webpack', options: { icon: true } }],\n      },\n    ],\n  },\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://react-svgr.com/docs/options/"
  }, "SVGR options reference"), " describes all options available."), "\n", React.createElement(_components.h2, {
    id: "use-svgr-and-asset-svg-in-the-same-project",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#use-svgr-and-asset-svg-in-the-same-project",
    "aria-label": "use svgr and asset svg in the same project permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Use SVGR and asset SVG in the same project"), "\n", React.createElement(_components.p, null, "You may be interested to use some SVG as an asset (url) and other SVG as a React component. The easiest way to do it is to use a ", React.createElement(_components.code, null, "resourceQuery"), " for one of the two type."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "webpack.config.js")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "module.exports = {\n  module: {\n    rules: [\n      {\n        test: /\\.svg$/i,\n        type: 'asset',\n        resourceQuery: /url/, // *.svg?url\n      },\n      {\n        test: /\\.svg$/i,\n        issuer: /\\.[jt]sx?$/,\n        resourceQuery: { not: [/url/] }, // exclude react component if *.svg?url\n        use: ['@svgr/webpack'],\n      },\n    ],\n  },\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Your code")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import svg from './assets/file.svg?url'\nimport Svg from './assets/file.svg'\n\nconst App = () => {\n  return (\n    <div>\n      <img src={svg} width=\"200\" height=\"200\" />\n      <Svg width=\"200\" height=\"200\" viewBox=\"0 0 3500 3500\" />\n    </div>\n  )\n}\n")), "\n", React.createElement(_components.h2, {
    id: "use-svg-in-css-files",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#use-svg-in-css-files",
    "aria-label": "use svg in css files permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Use SVG in CSS files"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "issuer: /\\.[jt]sx?$/"), " option ensures that SVGR will only apply if the SVG is imported from a JavaScript or a TypeScript file. It allows you to safely import SVG into your ", React.createElement(_components.code, null, ".css"), " or ", React.createElement(_components.code, null, ".scss"), " without any issue."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css"
  }, ".example {\n  background-image: url(./assets/file.svg);\n}\n")), "\n", React.createElement(_components.h2, {
    id: "use-with-url-loader-or-file-loader",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#use-with-url-loader-or-file-loader",
    "aria-label": "use with url loader or file loader permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Use with ", React.createElement(_components.code, null, "url-loader"), " or ", React.createElement(_components.code, null, "file-loader")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "url-loader"), " and ", React.createElement(_components.code, null, "file-loader"), " are deprecated over ", React.createElement(_components.a, {
    href: "https://webpack.js.org/guides/asset-modules/"
  }, "Asset Modules"), " in webpack v5. It is widely encouraged to use ", React.createElement(_components.code, null, "resourceQuery"), " method described before."), "\n"), "\n", React.createElement(_components.p, null, "SVGR can be used with ", React.createElement(_components.a, {
    href: "https://github.com/webpack-contrib/url-loader"
  }, React.createElement(_components.code, null, "url-loader")), " or ", React.createElement(_components.a, {
    href: "https://github.com/webpack-contrib/file-loader"
  }, React.createElement(_components.code, null, "file-loader")), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "webpack.config.js")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "module.exports = {\n  module: {\n    rules: [\n      {\n        test: /\\.svg$/i,\n        issuer: /\\.[jt]sx?$/,\n        use: ['@svgr/webpack', 'url-loader'],\n      },\n    ],\n  },\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Your code")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import starUrl, { ReactComponent as Star } from './star.svg'\n\nconst App = () => (\n  <div>\n    <img src={starUrl} alt=\"star\" />\n    <Star />\n  </div>\n)\n")), "\n", React.createElement(_components.p, null, "The named export defaults to ", React.createElement(_components.code, null, "ReactComponent"), " and can be customized with the ", React.createElement(_components.code, null, "namedExport"), " option."), "\n", React.createElement(_components.p, null, "Please note that by default, ", React.createElement(_components.code, null, "@svgr/webpack"), " will try to export the React Component via default export if there is no other loader handling svg files with default export. When there is already any other loader using default export for svg files, ", React.createElement(_components.code, null, "@svgr/webpack"), " will always export the React component via named export."), "\n", React.createElement(_components.p, null, "If you prefer named export in any case, you may set the ", React.createElement(_components.code, null, "exportType"), " option to ", React.createElement(_components.code, null, "named"), "."), "\n", React.createElement(_components.h2, {
    id: "use-your-own-babel-configuration",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#use-your-own-babel-configuration",
    "aria-label": "use your own babel configuration permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Use your own Babel configuration"), "\n", React.createElement(_components.p, null, "By default, ", React.createElement(_components.code, null, "@svgr/webpack"), " includes a ", React.createElement(_components.code, null, "babel-loader"), " with ", React.createElement(_components.a, {
    href: "https://github.com/gregberge/svgr/blob/main/packages/webpack/src/index.ts"
  }, "an optimized configuration"), ". In some case you may want to apply a custom one (if you are using Preact for an example). You can turn off Babel transformation by specifying ", React.createElement(_components.code, null, "babel: false"), " in options."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// Example using preact\n{\n  test: /\\.svg$/,\n  use: [\n    {\n      loader: 'babel-loader',\n      options: {\n        presets: ['preact', 'env'],\n      },\n    },\n    {\n      loader: '@svgr/webpack',\n      options: { babel: false },\n    }\n  ],\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
